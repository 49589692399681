<template>
  <div class="hidden alertSuccess" id="alertSuccess">
    <div class="flex bg-white rounded-lg items-center gap-3 shadow" style="padding: .85rem;">
    	<svg viewBox="64 64 896 896" data-icon="check-circle" width="1.4em" height="1.4em" fill="#12c310" aria-hidden="false"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64m193.5 301.7-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7"></path></svg>
    	<div class="text-base text-alertComp">Copiado com sucesso!</div>
    </div>
  </div>
  <div id="affiliate-page">
    <div class="navbar21">
      <div class="navbar21-left">
        <i class="fas fa-chevron-left" @click="goHome"></i>
      </div>
      <div class="navbar21-center">
        <p>Recomende amigos para abrir o baú do tesouro</p>
      </div>
      <div class="navbar21-right">
        <a href="#">Histórico</a>
      </div>
    </div>

    <div class="promo-info">
      <div class="promo-title">
        <h3>Informações da promoção</h3>
        <div class="title-line"></div>
      </div>
      <div class="promo-content">
        <div class="promo-left">
          <div class="qr-save-container">
            <img src="/assets/images/qrcode.png" alt="QR Code" class="qr-code" />
            <button class="save-btn">Clique para Salvar</button>
          </div>
        </div>
        <div class="promo-right">
          <div class="link-section">
            <div class="link-text">Meu Link</div>
            <div class="link-input">
              <div class="link-display">
                <div class="link-content">
                  {{ referencelink }}
                </div>
                <button class="copy-btn" @click="copyToClipboard(referencelink)"><i class="far fa-copy"></i></button>
              </div>
            </div>
          </div>
          <div class="info-section">
            <span>Subordinados diretos <span class="number">{{ indications }}</span></span>
            <span>
              Código de Convite: <span class="number">{{ referencecode }}</span>
              <button class="copy-btn-id" @click="copyToClipboard(referencecode)"><i class="far fa-copy"></i></button>
            </span>
          </div>
        </div>
      </div>
      <div class="share-icons">
        <div class="share-item">
          <div class="icon-circle">
            <i class="fas fa-share-square"></i>
          </div>
          <span>Partilhar</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/wa.png" alt="WhatsApp" />
          <span>WhatsApp</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/te.png" alt="Telegram" />
          <span>Telegram</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/ig.png" alt="Instagram" />
          <span>Instagram</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/fe.png" alt="Facebook" />
          <span>Facebook</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/tw.png" alt="X" />
          <span>TwitterP</span>
        </div>
        <div class="share-item">
          <img src="/assets/images/line.png" alt="Line" />
          <span>LinePartilhe</span>
        </div>
      </div>
      <div class="valid-info">
        <span>
          Subordinados válidos <span class="blue-number">0</span> pessoas
        </span>
        <a href="#" class="details-link">Detalhes</a>
      </div>
    </div>

    <div class="info">
      <p>
        O que é um número válido promocional? (Cumprir todos os requisitos
        indicados abaixo)
      </p>
      <div class="info-item-top">
        <span>Depósitos acumulados do subordinado</span><span>{{ bauValue.toFixed(2) }} ou mais</span>
      </div>
      <div class="info-item-bottom">
        <span>Apostas acumuladas do subordinado</span><span>100 ou mais</span>
      </div>
    </div>
    <div class="container">
      <!-- Linha 1 de baús -->
      <div class="row">
        <div class="chest" id="chest1" @click="verificarBau(1)">
          <img :src="chestImages[1]" alt="Baú" />
          <div class="chest-text">
            <p>1 pessoa</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest2" @click="verificarBau(2)">
          <img :src="chestImages[2]" alt="Baú" />
          <div class="chest-text">
            <p>2 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest3" @click="verificarBau(3)">
          <img :src="chestImages[3]" alt="Baú" />
          <div class="chest-text">
            <p>3 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest4" @click="verificarBau(4)">
          <img :src="chestImages[4]" alt="Baú" />
          <div class="chest-text">
            <p>4 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: 82%">
          <!-- Seta abaixo do baú 4 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 2 de baús -->
      <div class="row">
        <div class="chest" id="chest5" @click="verificarBau(5)">
          <img :src="chestImages[5]" alt="Baú" />
          <div class="chest-text">
            <p>5 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest6" @click="verificarBau(6)">
          <img :src="chestImages[6]" alt="Baú" />
          <div class="chest-text">
            <p>6 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest7" @click="verificarBau(7)">
          <img :src="chestImages[7]" alt="Baú" />
          <div class="chest-text">
            <p>7 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest8" @click="verificarBau(8)">
          <img :src="chestImages[8]" alt="Baú" />
          <div class="chest-text">
            <p>8 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: -82%">
          <!-- Seta abaixo do baú 1 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 3 de baús -->
      <div class="row">
        <div class="chest" id="chest9" @click="verificarBau(9)">
          <img :src="chestImages[9]" alt="Baú" />
          <div class="chest-text">
            <p>9 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest10" @click="verificarBau(10)">
          <img :src="chestImages[10]" alt="Baú" />
          <div class="chest-text">
            <p>10 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest11" @click="verificarBau(11)">
          <img :src="chestImages[11]" alt="Baú" />
          <div class="chest-text">
            <p>11 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest12" @click="verificarBau(12)">
          <img :src="chestImages[12]" alt="Baú" />
          <div class="chest-text">
            <p>12 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: 82%">
          <!-- Seta abaixo do baú 4 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 4 de baús -->
      <div class="row">
        <div class="chest" id="chest13" @click="verificarBau(13)">
          <img :src="chestImages[13]" alt="Baú" />
          <div class="chest-text">
            <p>13 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest14" @click="verificarBau(14)">
          <img :src="chestImages[14]" alt="Baú" />
          <div class="chest-text">
            <p>14 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest15" @click="verificarBau(15)">
          <img :src="chestImages[15]" alt="Baú" />
          <div class="chest-text">
            <p>15 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest16" @click="verificarBau(16)">
          <img :src="chestImages[16]" alt="Baú" />
          <div class="chest-text">
            <p>16 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: -82%">
          <!-- Seta abaixo do baú 1 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 5 de baús -->
      <div class="row">
        <div class="chest" id="chest17" @click="verificarBau(17)">
          <img :src="chestImages[17]" alt="Baú" />
          <div class="chest-text">
            <p>17 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest18" @click="verificarBau(18)">
          <img :src="chestImages[18]" alt="Baú" />
          <div class="chest-text">
            <p>18 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest19" @click="verificarBau(19)">
          <img :src="chestImages[19]" alt="Baú" />
          <div class="chest-text">
            <p>19 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest20" @click="verificarBau(20)">
          <img :src="chestImages[20]" alt="Baú" />
          <div class="chest-text">
            <p>20 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: 82%">
          <!-- Seta abaixo do baú 4 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 6 de baús -->
      <div class="row">
        <div class="chest" id="chest21" @click="verificarBau(21)">
          <img :src="chestImages[21]" alt="Baú" />
          <div class="chest-text">
            <p>21 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest22" @click="verificarBau(22)">
          <img :src="chestImages[22]" alt="Baú" />
          <div class="chest-text">
            <p>22 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest23" @click="verificarBau(23)">
          <img :src="chestImages[23]" alt="Baú" />
          <div class="chest-text">
            <p>23 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest24" @click="verificarBau(24)">
          <img :src="chestImages[24]" alt="Baú" />
          <div class="chest-text">
            <p>24 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: -82%">
          <!-- Seta abaixo do baú 1 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 7 de baús -->
      <div class="row">
        <div class="chest" id="chest25" @click="verificarBau(25)">
          <img :src="chestImages[25]" alt="Baú" />
          <div class="chest-text">
            <p>25 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest26" @click="verificarBau(26)">
          <img :src="chestImages[26]" alt="Baú" />
          <div class="chest-text">
            <p>26 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest27" @click="verificarBau(27)">
          <img :src="chestImages[27]" alt="Baú" />
          <div class="chest-text">
            <p>27 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest28" @click="verificarBau(28)">
          <img :src="chestImages[28]" alt="Baú" />
          <div class="chest-text">
            <p>28 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: 82%">
          <!-- Seta abaixo do baú 4 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 8 de baús -->
      <div class="row">
        <div class="chest" id="chest29" @click="verificarBau(29)">
          <img :src="chestImages[29]" alt="Baú" />
          <div class="chest-text">
            <p>29 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest30" @click="verificarBau(30)">
          <img :src="chestImages[30]" alt="Baú" />
          <div class="chest-text">
            <p>30 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest31" @click="verificarBau(31)">
          <img :src="chestImages[31]" alt="Baú" />
          <div class="chest-text">
            <p>31 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest32" @click="verificarBau(32)">
          <img :src="chestImages[32]" alt="Baú" />
          <div class="chest-text">
            <p>32 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: -82%">
          <!-- Seta abaixo do baú 1 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 9 de baús -->
      <div class="row">
        <div class="chest" id="chest33" @click="verificarBau(33)">
          <img :src="chestImages[33]" alt="Baú" />
          <div class="chest-text">
            <p>33 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest34" @click="verificarBau(34)">
          <img :src="chestImages[34]" alt="Baú" />
          <div class="chest-text">
            <p>34 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest35" @click="verificarBau(35)">
          <img :src="chestImages[35]" alt="Baú" />
          <div class="chest-text">
            <p>35 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-right"></i>
        </div>
        <div class="chest" id="chest36" @click="verificarBau(36)">
          <img :src="chestImages[36]" alt="Baú" />
          <div class="chest-text">
            <p>36 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
      <div class="row-arrow-down">
        <div class="arrow-down" style="margin-left: 82%">
          <!-- Seta abaixo do baú 4 -->
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- Linha 10 de baús -->
      <div class="row">
        <div class="chest" id="chest37" @click="verificarBau(37)">
          <img :src="chestImages[37]" alt="Baú" />
          <div class="chest-text">
            <p>37 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest38" @click="verificarBau(38)">
          <img :src="chestImages[38]" alt="Baú" />
          <div class="chest-text">
            <p>38 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest39" @click="verificarBau(39)">
          <img :src="chestImages[39]" alt="Baú" />
          <div class="chest-text">
            <p>39 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
        <div class="arrow">
          <i class="fas fa-angle-double-left"></i>
        </div>
        <div class="chest" id="chest40" @click="verificarBau(40)">
          <img :src="chestImages[40]" alt="Baú" />
          <div class="chest-text">
            <p>40 pessoas</p>
          </div>
          <div class="chest-value">{{ bauValue.toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div class="details">
      <h3>I. Condições do Evento:</h3>
      <p>
        Somente o subordinado recém-registrado, os subordinados atendem aos
        requisitos de atividade
      </p>
      <h3>II. Instruções Do Evento:</h3>
      <p>
        1. Convite amigos para abrir o Baú de Tesouros. Conclua com diferentes
        quantidades de pessoas para receber baús correspondentes, com um valor
        máximo de 20000. Quanto mais amigos você convidar, maior será a
        recompensa;
      </p>
      <p>
        2. Essa promoção é um bônus adicional da plataforma, e você também pode
        desfrutar de outras recompensas e comissões de agente, o que significa
        que você pode experimentar diretamente a alegria multiplicada;
      </p>
      <p>
        3. A recompensa é limitada à coleta manual no final do APP/iOS,
        APP/Android, PC/Windows, e o expirado será distribuído automaticamente;
      </p>
      <p>
        4. O bônus (excluindo o principal) concedido por esta atividade exige 1
        apostas válidas para liberar o saque, e as apostas são limitadas à
        Pescaria: Todos os jogos, Slots: Todos os jogos;
      </p>
      <p>
        5. Este evento é limitado a operações normais realizadas pelo titular
        da conta. É proibido alugar, usar plug-ins externos, robôs, apostar em
        contas diferentes, brushing mútuo, arbitragem, interface, protocolo,
        exploração de vulnerabilidades, controle de grupo ou outros meios
        técnicos para participar. Caso contrário, as recompensas serão
        canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada
        à lista negra;
      </p>
      <p>
        6. Para evitar diferenças na compreensão do texto, a plataforma
        reserva-se o direito de interpretação final deste evento.
      </p>
    </div>
  </div>
</template>

<script>
import HttpApi from "@/Services/HttpApi.js";
import { useRouter } from 'vue-router';

export default {
  name: "AffiliatePage",
  data() {
    return {
      referencecode: '',
      referencelink: '',
      indications: 0,
      bauValue: 0,
      chestImages: {}
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          const alertSuccess = document.getElementById('alertSuccess');
          if (alertSuccess) {
          	alertSuccess.classList.remove('hidden');
            alertSuccess.classList.add('flex');
            setTimeout(() => {
            	alertSuccess.classList.add('hidden');
            }, 3000);
          }
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    },
    getCode() {
      HttpApi.get('profile/affiliates/')
        .then(response => {
          if(response.data.code !== '' && response.data.code !== undefined && response.data.code !== null) {
            this.referencecode = response.data.code;
            this.referencelink = response.data.url;
          }
          this.indications = response.data.indications;
          this.bauValue = Number(response.data.bau_value);
          if(response.data.chest_images) {
            this.chestImages = response.data.chest_images;
          }
        })
        .catch(error => {
          console.error("Error fetching code: ", error);
        });
    },
    verificarBau(bauId) {
      HttpApi.post(`profile/verificar-bau/${bauId}`)
        .then(response => {
          if (response.data.status) {
            this.abrirBau(bauId);
          } else {
            console.error(response.data.error);
          }
        })
        .catch(error => {
          console.error("Error verifying chest: ", error);
        });
    },
    abrirBau(bauId) {
      HttpApi.post(`profile/abrir-bau/${bauId}`)
        .then(response => {
          console.log(response.data.message);
          this.getCode(); // Atualiza os dados para refletir o novo status dos baús
        })
        .catch(error => {
          console.error("Error opening chest: ", error);
        });
    },
    goHome() {
      console.log("Redirecting to home...");
      this.$router.push({ name: 'home' });
    }
  },
  created() {
    this.getCode();
  },
};
</script>




<style scoped>
/* Reset básico */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#affiliate-page {
    font-family: Arial, sans-serif;
    background-color: #0e131b;
    color: #fff;
    padding: 10px;
    overflow-x: hidden; /* Bloqueia o rolamento horizontal */
}

/* Estilo para a barra de rolagem */
::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
    height: 8px; /* Altura da barra de rolagem */
}

::-webkit-scrollbar-track {
    background: #151d29; /* Cor de fundo da trilha */
}

::-webkit-scrollbar-thumb {
    background-color: #293548; /* Cor da barra de rolagem */
    border-radius: 10px; /* Bordas arredondadas */
    border: 2px solid #151d29; /* Borda ao redor da barra de rolagem */
}

/* Para Firefox */
* {
    scrollbar-width: thin; /* Largura fina */
    scrollbar-color: #293548 #151d29; /* Cor da barra de rolagem e trilha */
}

/* Estilo para a barra de navegação */
.navbar21 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151d29;
    padding: 10px;
    color: #fff;
    border-bottom: 1px solid #293548;
    position: fixed; /* Fixar no topo */
    width: 100%; /* Largura total da tela */
    top: 0; /* Alinhar no topo */
    left: 0; /* Alinhar à esquerda */
    z-index: 1000; /* Garantir que fique acima de outros elementos */
    height: 52px; /* Definindo a altura da navbar */
}

.navbar21-left, .navbar21-right {
    flex: 1;
    text-align: center;
}

.navbar21-left {
    text-align: left;
    padding-left: 3px; /* Ajuste a posição para a direita */
}

.navbar21-left i {
    color: #adb6c4; /* Cor da seta */
    font-size: 1em; /* Tamanho da seta */
}

.navbar21-right {
    text-align: right;
}

.navbar21-center {
    flex: 3; /* Aumenta o espaço disponível para o texto central */
    text-align: center;
}

.navbar21 p {
    margin: 0;
    line-height: 0.9; /* Ajusta a altura da linha para que a quebra de linha fique mais junta */
}

.navbar21 a {
    color: #005dfe;
    text-decoration: none;
    font-size: 1em; /* Diminuir o tamanho da fonte do link */
}
  
.alertSuccess {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

/* CONTAINER QR CODE */
.promo-info {
    margin-top: 55px; /* Espaçamento para que o conteúdo não fique sob a navbar */
    display: flex;
    flex-direction: column;
}

.promo-title {
    margin-bottom: 15px; /* Espaçamento abaixo do título */
}
  
.text-alertComp {
  color: black !important;
}

.promo-title h3 {
    margin: 0;
    color: #ffffff; /* Cor do título */
    font-size: 1em; /* Tamanho do título */
    margin-top: 15px; /* Move o título um pouco para baixo */
    margin-left: 12px; /* Move o título um pouco para a direita */
}

.title-line {
   width: 100%; /* Largura da linha */
    height: 1px; /* Altura da linha, ajuste conforme necessário para grossura */
    background-color: #293548; /* Cor da linha */
    margin-top: 15px; /* Espaçamento acima da linha */
}

.info, .container, .details, .promo-info {
    background-color: #151d29;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
}

.promo-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.promo-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo-left img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.qr-save-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-save-container img {
    width: 92px;
    height: 90px;
    display: block;
    margin-bottom: 0; /* Remova a margem inferior */
    border-radius: 7px 7px 0px 0px;
    border: 5px solid #ffffff; /* Adicione a borda branca */
}

.save-btn {
    background-color: #005dfe;
    color: #fff;
    font-size: 1em;
    border: none;
    padding: 4px 0;
    border-radius: 0px 0px 12px 12px;
    cursor: pointer;
    width: 92px;
    height: auto;
    text-align: center;
    display: block;
    margin-top: 0px;
}

.link-text {
    margin-left: 0px; /* Ajuste conforme necessário */
    color: #adb6c4;
    font-size: 1em;
}

.save-btn i {
    margin-right: 5px;
}

.share-icons {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    padding: 10px 0;
}

.share-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    padding: 5px;
}

.custom-icon-container {
    padding: 10px;
}

.custom-icon {
    width: 45px;
    height: 45px;
    object-fit: cover;
}

.icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px; /* Tamanho uniforme dos ícones */
    height: 45px;
    border-radius: 50%;
    background-color: #29354800;/* Cor de fundo da bolinha */
    border: 1px solid #293548; /* Borda */
}

.icon-circle i {
    color: #005dfe; /* Cor do ícone */
}

.share-item img {
    width: 45px; /* Tamanho uniforme dos ícones */
    height: 45px;
    object-fit: cover;
}

.share-item span {
    color: #adb6c4; /* Cor do texto */
    font-size: 0.9em; /* Tamanho da fonte */
    margin-top: 5px; /* Espaçamento entre a imagem e o texto */
}



.valid-info {
    color: #55657e; /* Cor do texto */
    font-size: 0.9em; /* Tamanho da fonte */
    padding-left: 2px; /* Ajuste conforme necessário para mover para a direita */
    padding-top: 2px; /* Ajuste conforme necessário para mover para baixo */
}

.blue-number {
    color: #005dfe; /* Cor azul */
}

.valid-info a.details-link {
    color: #005dfe; /* Cor do link */
    text-decoration: underline;
    margin-left: 5px; /* Espaçamento à esquerda */
}

.promo-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13px; /* Espaçamento à esquerda do QR Code */
    margin-top: -1px; /* Ajuste negativo para subir a div */
}

.link-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link-text {
    color: #55657e;
    font-size: 1em;
}

.link-input {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px; /* Espaçamento superior */
    margin-bottom: 15px; /* Espaçamento inferior */
    font-size: 0.9em;
}

.link-display {
    flex: 1;
    padding: 5px;
    border: 1px solid #293548;
    border-radius: 5px;
    background-color: #151d29;
    color: #adb6c4;
    box-sizing: border-box;
    height: auto; /* Permitir altura automática */
    min-height: 50px; /* Altura mínima */
    display: flex;
    align-items: center; /* Centraliza o texto verticalmente */
    position: relative; /* Necessário para posicionar o botão dentro da div */
    padding-right: 40px; /* Espaçamento entre o texto e o botão */
    padding-left: 15px; /* Espaçamento à esquerda */
    max-width: 100%; /* Garante que a div não ultrapasse a largura do contêiner pai */
    overflow-wrap: break-word; /* Permitir quebra de linha para evitar estouro */
}

.link-content {
    flex: 1;
    word-break: break-word;
}

.copy-btn {
    background: none;
    border: none;
    color: #005dfe;
    cursor: pointer;
    position: absolute;
    right: 5px; /* Ajuste para mover um pouco para a esquerda */
    font-size: 1.4em; /* Aumenta o tamanho do ícone */
    padding: 5px; /* Adiciona espaçamento interno ao botão */
}



.info-section {
    margin-bottom: 5px;
    color: #55657e; /* Cor do texto */
    font-size: 0.9em; /* Tamanho da fonte */
}

.info-section span {
    display: inline-block; /* Exibir cada texto em linha */
    margin-bottom: 5px; /* Espaçamento entre as linhas */
}

.info-section .number {
    color: #fff; /* Cor branca para os números */
}

.copy-btn-id {
    background: none;
    border: none;
    color: #005dfe;
    cursor: pointer;
    font-size: 1.3em; /* Ajuste o tamanho conforme necessário */
    margin-left: 7px; /* Espaçamento à esquerda */
    vertical-align: middle; /* Alinhamento vertical */
}


.info p {
    text-align: center;
    color: #ccc;
    font-size: 3vw; /* Fonte ajustável */
    margin-bottom: 3vw; /* Espaçamento entre o título e o conteúdo abaixo */
}

.info-item-top, .info-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Centraliza verticalmente */
    padding: 3vw; /* Ajuste o padding conforme necessário */
    background-color: #0e131b; /* Cor de fundo mais escura */
    border-radius: 6px; /* Bordas arredondadas */
    margin-bottom: 2vw;
    color: #adb6c4; /* Ajuste da cor do texto */
    font-size: 3vw; /* Ajusta o tamanho da fonte das informações */
    white-space: nowrap; /* Impede quebra de linha */
}

.info-item-bottom {
    background-color: transparent; /* Remove o fundo azul */
}

.info-label, .info-value {
    margin: 0; /* Remove qualquer margem */
    padding: 0; /* Remove qualquer padding */
}

.info-item-top span, .info-item-bottom span {
    margin-left: 0; /* Reduz o espaço à esquerda do texto */
    margin-right: 0; /* Reduz o espaço à direita do texto */
    flex: 1; /* Permite que o texto ocupe o espaço necessário */
    text-align: left;
}

.info-item-top span:last-child, .info-item-bottom span:last-child {
    text-align: right; /* Alinha o texto à direita para os valores */
    color: #fff; /* Muda a cor dos valores para branco */
}

.container {
    display: flex;
    flex-direction: column;
    gap: 2vw; /* Espaçamento entre as linhas de baús */
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 1vw; /* Espaçamento entre os baús */
}

.chest {
    position: relative;
    padding: 0vw;
    text-align: center;
    flex: 1;
    min-width: 0; /* Remove largura mínima para garantir flexibilidade */
    max-width: calc(25% - 1vw); /* Ajusta a largura máxima de cada baú */
    box-sizing: border-box;
}

.chest img {
    width: 100%; /* Ajusta a largura da imagem do baú para ocupar todo o espaço disponível */
    height: auto; /* Mantém a proporção da imagem */
}

.chest-text {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2vw; /* Ajusta o tamanho da fonte */
    text-align: center;
    white-space: nowrap; /* Evita quebra de linha */
}

.chest-value {
    margin-top: 1vw;
    color: #55657e;
    font-size: 3vw; /* Ajusta o tamanho da fonte */
    white-space: nowrap; /* Evita quebra de linha */
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.3vw; /* Ajusta o tamanho das setas */
    color: #55657e;
    padding: 0 0vw; /* Ajusta o padding das setas */
    margin: 0 1vw; /* Ajusta o espaçamento entre as setas */
    white-space: nowrap; /* Evita quebra de linha */
}

.row-arrow-down {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0vw; /* Ajuste conforme necessário */
    margin-bottom: 0vw; /* Espaçamento inferior entre as linhas */
}

.arrow-down {
    font-size: 3.3vw; /* Tamanho das setas para baixo */
    color: #55657e;
}

.details h3 {
    margin-bottom: 3vw;
    color: #fff;
    font-size: 4vw; /* Ajusta o tamanho da fonte dos títulos */
}

.details p {
    text-align: left;
    margin-bottom: 3vw;
    color: #ccc;
    font-size: 3vw; /* Ajusta o tamanho da fonte dos parágrafos */
}
</style>
