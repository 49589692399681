<template>
    <div :key="index" class="game-list flex flex-col mt-5 relative">
        <div class="w-full flex justify-between mb-2">
            <h2 class="text-base font-bold text-white text-[16px]">{{ $t(provider.name) }}</h2>
            <div class="flex">
                <RouterLink
                    :to="{ name: 'casinosAll', params: { provider: isFeatured ? 'featured' : provider.id, category: 'all' } }"
                    class="inline-block rounded-full bg-[var(--ci-primary-opacity-color)] p-1 px-2 text-[10px] font-medium text-[var(--ci-primary-color)]">
                    {{ $t('See all') }}
                </RouterLink>
            </div>
        </div>

        <Carousel ref="ckCarousel"
          v-bind="settingsGames"
          :breakpoints="breakpointsGames"
          @init="onCarouselInit(index)"
          @slide-start="onSlideStart(index)"
        >
            
            <Slide v-show="isLoading" v-for="index in 5" :key="'loading-' + index" :index="index">
                <div role="status" class="w-full flex items-center justify-center h-48 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700 text-4xl">
                    <i class="fa-duotone fa-gamepad-modern"></i>
                </div>
            </Slide>

            
            <Slide 
                   v-if="filteredGames && !isLoading" 
                   v-for="(game, providerId) in filteredGames" 
                   :key="providerId" :index="providerId" 
                   class="!m-0 !mr-4">
              
              
                <CassinoGameCard
                    :index="providerId"
                    :title="game.game_name"
                    :cover="game.cover"
                    :gamecode="game.game_code"
                    :type="game.distribution"
                    :game="game"
                />
            </Slide>
        </Carousel>

    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import { onMounted, ref } from "vue";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";

export default {
    props: ['provider', 'index'],
    components: { CassinoGameCard, Carousel, Navigation, Slide },
    data() {
        return {
            isLoading: false,
            settingsGames: {
                itemsToShow: 2.5,
                snapAlign: 'start',
            },
            breakpointsGames: {
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 6,
                    snapAlign: 'start',
                },
            },
        }
    },
    setup(props) {
        const ckCarousel = ref(null);

        onMounted(() => {
        });

        return {
            ckCarousel
        };
    },
    computed: {
        filteredGames() {
            return this.provider.games ? this.provider.games.slice(0, 7) : [];
        },
      isFeatured() {
            return this.provider.name === "Recomendados";
        }
    },
    methods: {
        onCarouselInit(index) {
            
        },
        onSlideStart(index) {
            
        },
    },
};
</script>

<style scoped>
.text-provider {
    font-size: 18px;
    line-height: 1.75rem;
}
</style>
