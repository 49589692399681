<template>
    <RouterLink :to="getRouterLink()">
        <div class="flex text-gray-700 w-full h-auto mr-2 cursor-pointer relative" @mouseover="showGameInfo = true" @mouseleave="showGameInfo = false">
            <div class="relative">
                <RouterLink :to="{ name: 'casinoPlayPage', params: { id: game.id, slug: game.game_code }}">
                    <img v-lazy="`/storage/` + game.cover" alt="" class="rounded-lg lg:w-auto" width="133" height="200" :style="{ opacity: showGameInfo ? '1' : '1' }">
                </RouterLink>
                <div v-if="showGameInfo" class="absolute inset-0 flex justify-center items-center bg-opacity-10 px-3 py-2">
                    <div class="text-center text-white max-w-[100%]"><!--
                        <span class="block truncate text-[12px]">{{ game.game_name }}</span>
                        <small class="block truncate text-[10px]">{{ game?.provider?.name }}</small>-->
                        <button type="button" class="mt-2 px-2 py-2 text-white rounded mx-auto px-4" style="background-color: var(--ci-primary-color);">
                            JOGAR
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </RouterLink>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";
import VueLazyload from 'vue-lazyload';

export default {
    props: ['category', 'index'],
    components: { CassinoGameCard, Carousel, Navigation, Slide },
    data() {
        return {
            isLoading: false,
            settingsGames: {
                itemsToShow: 2.5,
                snapAlign: 'start',
            },
            breakpointsGames: {
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 6,
                    snapAlign: 'start',
                },
            },
        }
    },
    setup(props) {
        const ckCarousel = ref(null);

        onMounted(() => {
            // Any setup needed
        });

        return {
            ckCarousel
        };
    },
    methods: {
        onCarouselInit(index) {
            // Initialization logic
        },
        onSlideStart(index) {
            // Slide start logic
        },
    },
};
</script>

