<template>
    <RouterLink :to="getRouterLink()">
        <div class="flex text-gray-700 w-full h-auto mr-4 cursor-pointer relative" @mouseover="showGameInfo = true" @mouseleave="showGameInfo = false">
            <div class="relative">
                <img
                    v-if="imageLoaded"
                    :src="imageSrc"
                    alt=""
                    class="rounded-lg lg:w-auto"
                    width="133"
                    height="200"
                    :style="{ opacity: showGameInfo ? '0.5' : '1' }"
                />
                <div v-if="showGameInfo" class="absolute inset-0 flex justify-center items-center bg-opacity-50 px-3 py-2">
                    <div class="text-center text-white max-w-[100%]"><br/>
                       <span class="block truncate text-[12px]">{{ game.game_name }}</span>
                        <small class="block truncate text-[10px]">{{ game?.provider?.name }}</small>
                        <button type="button" class="mt-2 px-1 py-1 text-white rounded mx-auto px-4" style="background-color: var(--ci-primary-color);">
                            JOGAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </RouterLink>
</template>

<script>
export default {
    props: ['index', 'game'],
    data() {
        return {
            showGameInfo: false,
            imageLoaded: false,
            imageSrc: "",
            currentNumber: this.getRandomNumber(),
            targetNumber: 0,
        };
    },
    mounted() {
        this.preloadImage();
        this.updateNumber();
        setInterval(() => {
            this.updateNumber();
        }, 20000);
    },
    methods: {
        getRouterLink() {
            return { name: 'casinoPlayPage', params: { id: this.game.id, slug: this.game.game_code } };
        },
        getRandomNumber() {
            return Math.floor(Math.random() * 1100);
        },
        updateNumber() {
            this.targetNumber = this.getRandomNumber();
            const difference = this.targetNumber - this.currentNumber;
            const changePerInterval = difference / (10000 / 500);
            this.changeNumber(changePerInterval);
        },
        changeNumber(changePerInterval) {
            const timer = setInterval(() => {
                if ((changePerInterval > 0 && this.currentNumber < this.targetNumber) ||
                    (changePerInterval < 0 && this.currentNumber > this.targetNumber)) {
                    this.currentNumber += changePerInterval;
                } else {
                    this.currentNumber = this.targetNumber;
                    clearInterval(timer);
                }
            }, 500);
        },
        preloadImage() {
            const img = new Image();
            img.src = `/storage/${this.game.cover}`;
            img.onload = () => {
                this.imageSrc = img.src;
                this.imageLoaded = true;
            };
        },
    },
};
</script>




<style scoped>
  
@keyframes myAnim {
    0% {
        animation-timing-function: ease-in;
        opacity: 1;
        transform: translateY(-45px);
    }
    24% {
        opacity: 1;
    }
    40% {
        animation-timing-function: ease-in;
        transform: translateY(-24px);
    }
    65% {
        animation-timing-function: ease-in;
        transform: translateY(-12px);
    }
    82% {
        animation-timing-function: ease-in;
        transform: translateY(-6px);
    }
    93% {
        animation-timing-function: ease-in;
        transform: translateY(-4px);
    }
    25%, 55%, 75%, 87% {
        animation-timing-function: ease-out;
        transform: translateY(0px);
    }
    100% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0px);
    }
}

.my-animation {
    animation: myAnim 1s;
}

.item-game-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.online-indicator {
  margin-top: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pulse {
  margin-left: 12px;
  width: 8px;
  height: 8px;
  background-color: #00ff00;
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse-animation 1s infinite;
  box-shadow: 0 0 5px #00ff00, 0 0 20px #00ff00;
}

/* Estilo quando houver mais de 950 jogadores */
.high-players .pulse, .gold .pulse {
  background-color: gold;
  box-shadow: 0 0 5px gold, 0 0 20px gold;
}

/* Alinhamento do número com o card */
.high-players strong, .gold strong {
  margin-left: 0;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>