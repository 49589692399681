<template>
  <div class="container">
    <div class="mx-4 w-full">
      <div class="mb-5">
        <div class="overflow-hidden relative pt-4">
          <div class="scroll-container">
            <div class="scroll-content" :style="{ animationDuration: animationDuration + 's' }">
              <div v-for="(item, index) in items" :key="index" class="info-box shadow-sm rounded-lg flex gap-4 w-[260px] items-center px-4 h-[90px] mb-4">
                <div>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.7171 24.2659C24.1053 24.2681 23.4992 24.1491 22.9337 23.9157C22.3682 23.6823 21.8546 23.3391 21.4225 22.9061L16.6635 18.1441C16.4952 17.9831 16.2713 17.8933 16.0384 17.8933C15.8055 17.8933 15.5815 17.9831 15.4132 18.1441L10.6382 22.9191C10.2063 23.3528 9.69285 23.6967 9.12736 23.9308C8.56188 24.1649 7.95561 24.2846 7.34358 24.283H6.41189L12.4423 30.3134C13.3467 31.2159 14.5722 31.7228 15.8499 31.7228C17.1276 31.7228 18.3531 31.2159 19.2576 30.3134L25.3 24.2699L24.7171 24.2659Z" fill="white"/>
                    <path d="M7.34459 7.43721C7.95662 7.43558 8.56291 7.55527 9.1284 7.78937C9.6939 8.02347 10.2074 8.36733 10.6392 8.80108L15.4142 13.5771C15.5802 13.7426 15.805 13.8355 16.0394 13.8355C16.2737 13.8355 16.4985 13.7426 16.6645 13.5771L21.4225 8.81917C21.8539 8.38488 22.3673 8.04059 22.9329 7.80629C23.4984 7.572 24.1049 7.45235 24.7171 7.4543H25.29L19.2475 1.41187C18.8001 0.964263 18.2688 0.609192 17.6841 0.36694C17.0995 0.124687 16.4728 0 15.8399 0C15.207 0 14.5803 0.124687 13.9956 0.36694C13.4109 0.609192 12.8796 0.964263 12.4322 1.41187L6.41189 7.43721H7.34459Z" fill="white"/>
                    <path d="M30.2881 12.4535L26.6367 8.80209C26.5544 8.83575 26.4664 8.85349 26.3774 8.85435H24.7171C23.8531 8.85652 23.0248 9.19965 22.4125 9.80916L17.6545 14.5641C17.226 14.9918 16.6453 15.232 16.0399 15.232C15.4344 15.232 14.8537 14.9918 14.4252 14.5641L9.64919 9.79107C9.03698 9.18119 8.20873 8.83769 7.34459 8.83526H5.30632C5.22232 8.83337 5.13931 8.8167 5.06109 8.78601L1.38659 12.4535C0.484075 13.3579 -0.022789 14.5834 -0.022789 15.8611C-0.022789 17.1388 0.484075 18.3644 1.38659 19.2688L5.05305 22.9353C5.1311 22.904 5.21422 22.8873 5.29828 22.886H7.34459C8.20871 22.8835 9.03692 22.54 9.64919 21.9302L14.4242 17.1552C15.2876 16.2928 16.7922 16.2928 17.6545 17.1552L22.4125 21.9121C23.0248 22.5216 23.8531 22.8648 24.7171 22.8669H26.3774C26.4664 22.8675 26.5545 22.8853 26.6367 22.9192L30.2881 19.2678C30.7357 18.8204 31.0908 18.2891 31.333 17.7044C31.5753 17.1197 31.7 16.493 31.7 15.8601C31.7 15.2272 31.5753 14.6005 31.333 14.0158C31.0908 13.4312 30.7357 12.8999 30.2881 12.4525" fill="white"/>
                  </svg>
                </div>
                <div class="w-[230px] flex flex-col">
                  <p class="text-sm font-bold text-white">{{ item.value }}</p>
                  <div class="flex flex-col">
                    <p class="text-left w-full mr-1 text-sm text-white font-bold"> {{ item.name }}</p>
                    <p class="text-xs text-white">acabou de <strong class="font-bold">SACAR</strong> via <strong class="font-bold">PIX</strong></p>
                  </div>
                </div>
              </div>
            </div>        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    const names = [
      'Camila B****', 'Guilherme S****', 'Rafaela O****', 'Hugo L****', 'Roberta R****',
      'Fernando P****', 'Isabela F****', 'Gabriela R****', 'Camila R****', 'Thales C****',
      'Maria A****', 'Vitor L****', 'Vinícius M****', 'Aline S****', 'Priscila L****',
      'Fernanda A****', 'Mariana C****', 'Rodrigo O****', 'Carla S****', 'Renato A****',
      'Eduardo S****', 'Gabriel N****', 'Camila K****', 'Lucas S****', 'Beatriz F****',
      'Thiago L****', 'Laura R****', 'Carlos A****', 'Mariana G****', 'Rodrigo P****',
      'Ana C****', 'Juliana H****', 'Rafael V****', 'Patrícia M****', 'Vinícius B****',
      'Cristina E****', 'Diego R****', 'Ana Paula L****', 'Fernanda S****', 'Pedro H****',
      'Aline N****', 'Ricardo C****', 'Carla G****', 'Henrique F****', 'Larissa A****',
      'Felipe L****', 'Ana L****', 'Pedro M****', 'Fernanda N****', 'Leonardo M****',
      'Gabriela P****', 'Lucas G****', 'Marcelo A****', 'Letícia R****', 'Thiago P****'
    ];

    return {
      items: Array.from({ length: 50 }, () => ({
        value: `R$ ${(Math.floor(Math.random() * 900) + 100).toFixed(2)}`,
        name: names[Math.floor(Math.random() * names.length)],
      })),
      animationDuration: 120
    };
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.text-white {
  color: white;
}

.text-xl {
  font-size: 1.25rem;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.pt-4 {
  padding-top: 0.5rem;
}

.scroll-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.scroll-content {
  display: flex;
  gap: 5px;
  animation: scroll 120s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scroll-container:before,
.scroll-container:after {
  position: absolute;
  top: 0;
  width: 100px;
  height: 90px;
  content: "";
  z-index: 2;
}

.scroll-container:before {
  left: 0;
  background: linear-gradient(to right, rgba(18, 19, 23, 1) 0%, rgba(18, 19, 23, 0) 100%);
}

.scroll-container:after {
  right: 0;
  background: linear-gradient(to left, rgba(18, 19, 23, 1) 0%, rgba(18, 19, 23, 0) 100%);
}

.info-box {
  background: linear-gradient(to right, rgba(18, 19, 23, 1) 0%, rgba(18, 19, 23, 0) 100%);
  transition: transform 0.3s ease; /* Adicionado para hover */
}

.info-box:hover {
  transform: scale(1.05); /* Adicionado para hover */
}

.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.flex {
  display: flex;
}

.gap-4 {
  gap: 1rem;
}

.w-[260px] {
  width: 260px;
}

.items-center {
  align-items: center;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.h-[90px] {
  height: 90px;
}

.mb-4 {
  margin-bottom: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.font-bold {
  font-weight: 700;
}

.w-full {
  width: 100%;
}

.mr-1 {
  margin-right: 0.25rem;
}

.text-xs {
  font-size: 0.75rem;
}
</style>

