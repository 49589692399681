<template>
    <GameLayout>
        <div v-if="!isLoading && game" class="fullscreen-container" ref="fullscreenContainer">
            <div class="button-container">
                <!-- home independente de fullscreen -->
                <div class="moveable-button" ref="homeButton" @click="goHome">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="black"/>
                    </svg>
                </div>
            </div>
            <iframe :src="gameUrl" class="game-full" ref="gameFrame"></iframe>
        </div>

        <div v-if="undermaintenance" class="flex flex-col items-center justify-center text-center py-24">
            <h1 class="text-2xl mb-4">JOGO EM MANUTENÇÃO</h1>
            <img :src="`/assets/images/work-in-progress.gif`" alt="" width="400">
        </div>
    </GameLayout>
</template>


<script>
import { reactive, onMounted } from 'vue';
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import HttpApi from "@/Services/HttpApi.js";

import { RouterLink, useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/Stores/Auth.js";

export default {
    components: {
        GameLayout,
        LoadingComponent,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const state = reactive({
            fullscreen: true,
            pageOnly: false,
        });

        const enterFullscreen = async (container) => {
            if (container.requestFullscreen) {
                await container.requestFullscreen();
            } else if (container.mozRequestFullScreen) {
                await container.mozRequestFullScreen();
            } else if (container.webkitRequestFullscreen) {
                await container.webkitRequestFullscreen();
            } else if (container.msRequestFullscreen) {
                await container.msRequestFullscreen();
            }
        };

        const exitFullscreen = async () => {
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                await document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                await document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                await document.msExitFullscreen();
            }
        };

        onMounted(() => {
            const container = document.getElementById('game-screen');
            if (container) {
                enterFullscreen(container);
            }
            const moveableButton = document.querySelector('.moveable-button');
            let isMouseDown = false;
            let offset = [0, 0];

            moveableButton.addEventListener('mousedown', (e) => {
                isMouseDown = true;
                offset = [
                    moveableButton.offsetLeft - e.clientX,
                    moveableButton.offsetTop - e.clientY
                ];
            });

            document.addEventListener('mouseup', () => {
                isMouseDown = false;
            });

            document.addEventListener('mousemove', (event) => {
                event.preventDefault();
                if (isMouseDown) {
                    const mousePosition = {
                        x: event.clientX,
                        y: event.clientY
                    };
                    moveableButton.style.left = (mousePosition.x + offset[0]) + 'px';
                    moveableButton.style.top = (mousePosition.y + offset[1]) + 'px';
                }
            });

            window.addEventListener('orientationchange', () => {
                const fullscreenContainer = document.querySelector('.fullscreen-container');
                const gameFrame = document.querySelector('.game-full');

                if (fullscreenContainer && gameFrame) {
                    fullscreenContainer.style.height = '100vh';
                    fullscreenContainer.style.width = '100vw';
                    gameFrame.style.height = '100vh';
                    gameFrame.style.width = '100vw';
                }
            });
        });

        return {
            ...state,
            router,
            route,
            enterFullscreen,
            exitFullscreen
        };
    },
    data() {
        return {
            isLoading: true,
            game: null,
            gameUrl: null,
            token: null,
            gameId: null,
            undermaintenance: false,
        }
    },
    computed: {
        userData() {
            const authStore = useAuthStore();
            return authStore.user;
        },
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
    },
    methods: {
        async checkMaintenance() {
            try {
                const response = await HttpApi.get(`maintenance/status/${this.gameId}`);
                this.undermaintenance = response.data.undermaintenance;
            } catch (error) {
                console.error('Error checking maintenance status:', error);
            }
        },
        getGame: async function() {
            const _this = this;

            return await HttpApi.get('games/single/'+ _this.gameId)
                .then(async response =>  {
                    if(response.data?.action === 'deposit') {
                        _this.$nextTick(() => {
                            _this.router.push({ name: 'profileDeposit' });
                        });

                    }

                    const game = response.data.game;
                    _this.game = game;

                    _this.gameUrl = response.data.gameUrl;
                    _this.token = response.data.token;
                    _this.isLoading = false;

                    _this.$nextTick(() => {
                        _this.loadingTab();
                    });
                })
                .catch(error => {

                    _this.isLoading = false;
                    _this.undermaintenance = true;
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {

                    });
                });
        },
        toggleFavorite() {
            HttpApi.post(`games/favorite/${this.game.id}`, {})
                .then(response => {
                    this.getGame();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    console.error('Error toggling favorite:', error);
                });
        },
        async toggleLike() {
            try {
                await HttpApi.post(`games/like/${this.game.id}`, {});
                await this.getGame();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error toggling like:', error);
            }
        },
        async exitFullscreenAndGoHome() {
            try {
                await this.exitFullscreen();
            } catch (error) {
                console.warn('Não estava em modo de tela cheia ou erro ao tentar sair:', error);
            }
            this.router.push({ name: 'home' });
        },
        goHome() {
            this.router.push({ name: 'home' });
        }
    },
    async created() {
        if (this.isAuthenticated) {
            const route = useRoute();
            this.gameId = route.params.id;

            await this.checkMaintenance();

            if (!this.undermaintenance) {
                await this.getGame();
            }
        } else {
            this.router.push({
                name: "login",
                params: { action: "openlogin" },
            });
        }
    },
    watch: {},
};
</script>



<style scoped>
.fullscreen-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.game-full {
    width: 100%;
    height: 100%;
    border: none;
}

.moveable-button {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10001;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
}

.moveable-button:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1);
}

.moveable-button svg {
    width: 24px;
    height: 24px;
}

@media (max-width: 1024px) {
    .moveable-button {
        display: flex;
    }
}

@media (max-width: 768px) {
    .moveable-button {
        width: 50px;
        height: 50px;
        top: 20px;
    }

    .moveable-button svg {
        width: 30px;
        height: 30px;
    }
}

@supports (-webkit-touch-callout: none) {
    .fullscreen-container {
        height: calc(100% - env(safe-area-inset-bottom));
        height: -webkit-fill-available;
    }

    .game-full {
        height: calc(100% - env(safe-area-inset-bottom));
        height: -webkit-fill-available;
    }
}

/* Ajustes adicionais para orientação */
@media (orientation: landscape) {
    .fullscreen-container {
        height: 100vh;
        width: 100vw;
    }

    .game-full {
        height: 100vh;
        width: 100vw;
    }
}
</style>
