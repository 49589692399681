<template>
  <BaseLayout>
    <div class="container mx-auto p-4 mt-20 relative min-h-[calc(100vh-565px)]">
      <div class="grid grid-cols-1 gap-4">
        <div class="info-box p-6">
          <h2 class="text-white text-lg md:text-2xl font-bold">Informações gerais</h2>
          <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col mb-2">
              <label for="email" class="label">E-mail</label>
              <input type="email" id="email" v-model="email" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="userId" class="label">ID de usuário</label>
              <input type="text" id="userId" value="510891c17d4caffbfddab45e6cc7111b" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="dob" class="label">Data de nascimento</label>
              <input type="text" id="dob" value="20/01/2005" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="phone" class="label">Telefone</label>
              <div class="phone-input-container">
                <div class="dropdown" id="phone-dropdown">
                  <button class="dropbtn" @click="toggleDropdown">
                    <span class="flag-icon-container"><span class="flag-icon flag-icon-br"></span></span> +55
                  </button>
                  <div class="dropdown-content">
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-us"></span></span> +1</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ca"></span></span> +1</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ru"></span></span> +7</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-eg"></span></span> +20</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-za"></span></span> +27</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-nl"></span></span> +31</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-fr"></span></span> +33</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-es"></span></span> +34</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-it"></span></span> +39</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-gb"></span></span> +44</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-se"></span></span> +46</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-de"></span></span> +49</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-pe"></span></span> +51</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-mx"></span></span> +52</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-cl"></span></span> +56</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-co"></span></span> +57</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ve"></span></span> +58</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-au"></span></span> +61</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-nz"></span></span> +64</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-jp"></span></span> +81</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-kr"></span></span> +82</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-tr"></span></span> +90</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-in"></span></span> +91</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ng"></span></span> +234</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-et"></span></span> +251</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ke"></span></span> +254</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-bz"></span></span> +501</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-gt"></span></span> +502</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-sv"></span></span> +503</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-hn"></span></span> +504</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ni"></span></span> +505</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-cr"></span></span> +506</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-pa"></span></span> +507</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-bo"></span></span> +591</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-ec"></span></span> +593</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-py"></span></span> +595</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-uy"></span></span> +598</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-fj"></span></span> +679</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-sa"></span></span> +966</a>
                    <a href="#"><span class="flag-icon-container"><span class="flag-icon flag-icon-il"></span></span> +972</a>
                  </div>
                </div>
                <input type="text" id="phone" value="(41) 98448-7588" class="input phone-input">
              </div>
            </div>
          </div>
          <div class="flex justify-start items-center mt-4 gap-4">
            <button class="ui-button-violet-medium flex items-center">
              <i class="fa fa-check mr-2"></i> Salvar
            </button>
          </div>
        </div>
        <div class="info-box p-6">
          <h2 class="text-white text-lg md:text-2xl font-bold">Meu Pix</h2>
          <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col mb-2">
              <label for="key-type" class="label">Tipo de chave</label>
              <select id="key-type" class="input">
                <option>CPF</option>
                <option>Telefone</option>
                <option>E-mail</option>
                <option>Chave Aleatória</option>
              </select>
            </div>
            <div class="flex flex-col mb-2">
              <label for="pix-key" class="label">Chave pix</label>
              <input type="text" id="pix-key" value="155.732.899-42" class="input">
            </div>
          </div>
          <div class="flex justify-start items-center mt-4 gap-4">
            <button class="ui-button-violet-medium flex items-center">
              <i class="fa fa-check mr-2"></i> Salvar
            </button>
          </div>
        </div>
        <div class="info-box p-6">
          <h2 class="text-white text-lg md:text-2xl font-bold">Endereço</h2>
          <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col mb-2">
              <label for="city" class="label">Cidade</label>
              <input type="text" id="city" placeholder="Cidade" class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="state" class="label">Estado</label>
              <input type="text" id="state" placeholder="Estado" class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="address" class="label">Endereço</label>
              <input type="text" id="address" placeholder="Endereço" class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="zip" class="label">Cep</label>
              <input type="text" id="zip" placeholder="Cep" class="input">
            </div>
          </div>
          <div class="flex justify-start items-center mt-4 gap-4">
            <button class="ui-button-violet-medium flex items-center">
              <i class="fa fa-check mr-2"></i> Salvar
            </button>
          </div>
        </div>
        <div class="info-box p-6">
          <h2 class="text-white text-lg md:text-2xl font-bold">Informações do usuário</h2>
          <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col mb-2">
              <label for="name" class="label">Nome</label>
              <input type="text" id="name" value="PABLO DE MELLO VERONI" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="country" class="label">País</label>
              <input type="text" id="country" value="BRA" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="currency" class="label">Moeda</label>
              <input type="text" id="currency" value="BRL" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="language" class="label">Idioma</label>
              <div class="flex">
                <input type="text" id="language" value="pt-br" class="input phone-code">
                <button class="ui-button-violet-medium flex items-center">
                  <i class="fa fa-check mr-2"></i> Alterar Idioma
                </button>
              </div>
            </div>
            <div class="flex flex-col mb-2">
              <label for="timezone" class="label">Fuso Horário</label>
              <input type="text" id="timezone" value="America/Sao_Paulo" disabled class="input">
            </div>
            <div class="flex flex-col mb-2">
              <label for="document" class="label">Documento</label>
              <input type="text" id="document" value="155.732.899-42" disabled class="input">
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";

export default {
  components: { BaseLayout },
  data() {
    return {
      dropdownOpen: false,
      email: ''
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    closeDropdown(event) {
      const dropdown = document.getElementById("phone-dropdown");
      if (!dropdown.contains(event.target)) {
        this.dropdownOpen = false;
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    async fetchEmail() {
      try {
        const response = await HttpApi.get('profile/email');
        this.email = response.data.email;
      } catch (error) {
        const _toast = useToast();
        _toast.error('Erro ao buscar o email.');
      }
    }
  },
  watch: {
    dropdownOpen(newVal) {
      const content = document.querySelector(".dropdown-content");
      content.style.display = newVal ? "block" : "none";
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  mounted() {
    this.fetchEmail();
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

.label {
  margin-bottom: 2px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: #3e3e3e;
  color: #ddd;
  margin-bottom: 8px;
}

.info-box h2 {
  margin-bottom: 16px;
}

.phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.phone-input {
  flex-grow: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  padding: 10px;
  border: 1px solid #444;
  background-color: #3e3e3e;
  color: #ddd;
}

.ui-button-violet-medium {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.ui-button-violet-medium:hover {
  background-color: #357ab7;
}

.info-box {
  background-color: #2d2d2d;
  width: 80%;
  margin: 0 auto 10px;
  border-radius: 8px;
  border: 1px solid #444;
}

.empty-box {
  background-color: #2d2d2d;
  height: 200px;
  width: 70%;
  margin: 0 auto 10px;
  border-radius: 8px;
  border: 1px solid #444;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #444;
  border-radius: 4px 0 0 4px;
  background-color: #3e3e3e;
  padding: 0 10px;
  margin: 0;
  cursor: pointer;
  height: 100%;
}

.dropdown-content a {
  color: white;
  padding: 6px 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.dropdown-content a:hover {
  background-color: #575757;
}

.dropbtn {
  color: white;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  background-color: #3e3e3e;
  height: 100%;
  border-radius: 4px 0 0 4px;
  padding: 0 20px;
  width: 100%;
  justify-content: space-between;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #3e3e3e;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: calc(100% + 2px);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #444;
  border-top: none;
  margin: 0;
  left:0;
}

.flag-icon-container {
  background-color: #3e3e3e;
  border-radius: 50%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px; /* Adicione esta linha para o espaçamento */
}

.flag-icon {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.phone-code {
  width: 60px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.phone-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-grow: 1;
}
</style>
