<template>
    <transition name="fade">
        <div
            v-if="isLoading"
            class="is-loading-component w-full h-[calc(100vh-60px)] z-[999999]"
        >
            <div
                class="absolute -top-10 z-20 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#9D93EC] opacity-25 w-72 h-56 blur-[30px]"
            ></div>
            <div
                class="absolute -bottom-10 z-20 left-1/2 -translate-x-1/2 translate-y-1/2 rounded-full bg-[#9D93EC] opacity-25 w-72 h-56 blur-[30px]"
            ></div>
            <div class="text-center flex-col">
                <div
                    role="status"
                    class="absolute z-10 grid -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                    <img
                        :src="`/assets/images/loader/loading.webp`"
                        alt=""
                        width="400"
                        loading="lazy"
                    />
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ["isLoading"],
    components: {},
    data() {
        return {
            shouldRender: true,
        };
    },
    watch: {
        isLoading(newVal) {
            if (!newVal) {
                setTimeout(() => {
                    this.shouldRender = false;
                }, 500); // Tempo de espera correspondente à duração da transição CSS
            } else {
                this.shouldRender = true;
            }
        },
    },
    setup(props) {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>

<style>
.is-loading-component {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-out {
    height: 0 !important;
    opacity: 0 !important;
}
</style>
