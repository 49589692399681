<template>
  <BaseLayout>

    <div style="margin-top: -70px"
    class="w-[100%] md:w-[100%] px-[0px] md:px-3 container mx-auto md:mx-auto flex justify-center items-center flex-col">

    <!-- Banners carousel -->
    <div class="min-h-[200px] rounded-3xl carousel-banners w-[100%] flex justify-center items-center">
        <div class="px-[0px] rounded-3xl md:px-4 pt-4 w-[100%]">
        <div>
            <Carousel class="rounded-3xl" v-bind="settings" :breakpoints="breakpoints" ref="carouselBanner">
              <Slide v-for="(banner, index) in banners" :key="index">
                <div class="carousel__item rounded-3xl w-full flex justify-center items-center">
                  <a :href="banner.link" class="w-full h-full bg-transparent rounded-3xl flex justify-center items-center">
                    <img 
                      :src="preloadedImages[banner.image] || `/storage/` + banner.image" 
                      alt=""
                      :class="{
                        'w-full object-cover': imageLoaded[banner.image],
                        'w-[600px] h-[100%] object-contain': !imageLoaded[banner.image]
                      }"
                      loading="lazy"
                      @load="imageLoaded[banner.image] = true"
                      class="h-full rounded-3xl transition-all duration-1000 ease-in-out"
                    />
                  </a>
                </div>
              </Slide>

            <template #addons>
                <navigation>
                <template #next>
                    <i class="fa-solid fa-chevron-right text-white"></i>
                </template>
                <template #prev>
                    <i class="fa-solid fa-chevron-left text-white"></i>
                </template>
                </navigation>
                <Pagination />
            </template>
            </Carousel>
        </div>
        </div>
    </div>

    <!-- Searchbar action -->
    <div class="w-full mx-auto p-4 mb-2">
        <div class="cursor-pointer w-full relative">
        <div class="flex">
            <!-- Input de pesquisa -->
            <div class="relative rounded-xl w-full">
            <input @click.prevent="toggleSearch" type="search" readonly
                class="block dark:focus:border-green-500 p-2.5 pl-10 w-full z-20 text-sm text-gray-900 rounded-lg input-color-primary border-none focus:outline-none dark:border-s-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                placeholder="Pesquisar" required />
            <!-- Ícone de pesquisa dentro do input -->
            <svg class="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
            </div>
        </div>
        </div>

        <!-- categories -->
        <div v-if="categories" class="category-list mt-4">
        <div class="flex mb-5 gap-4" style="max-height: 200px; overflow-x: auto; overflow-y: hidden;">
            <div class="flex flex-row justify-between items-center w-full" style="min-width: 100%; white-space: nowrap;">
            <RouterLink :to="{ name: 'casinosAll', params: { provider: 'all', category: category.slug }}"
                v-for="(category, index) in categories"
                class="flex flex-col justify-center items-center min-w-[80px] text-center">
                <div
                class="nui-mask nui-mask-hexed dark:bg-muted-800 flex size-16 scale-95 items-center justify-center dark:bg-[var(--ci-primary-opacity-color)] p-4 shadow-lg">
                <img :src="`/storage/` + category.image" alt="" width="35" loading="lazy" class="">
                </div>
                <p class="mt-3">{{ $t(category.name) }}</p>
            </RouterLink>
            </div>
        </div>
        


        <div class="w-full mx-auto mt-4">
          <div>
              <LastWinnersComponent />
          </div>
        </div>

		</div>

        <div class="w-full mx-auto mt-4">
          <template v-if="!isLoading">
              <ShowProviders v-for="(provider, index) in providers" :provider="provider" :index="index" />
          </template>
          <template v-else>
              <div class="placeholder w-full h-full flex justify-center items-center bg-gray-200 rounded">
                <div class="spinner">
                  
                </div>
              </div><br/>
            <center><p>Carregando Jogos...</p></center>
          </template>
        </div>
    </div>

    </div>
  </BaseLayout>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import { onMounted, ref } from 'vue';
import BaseLayout from "@/Layouts/BaseLayout.vue";
import MakeDeposit from "@/Components/UI/MakeDeposit.vue";
import { RouterLink, useRoute } from "vue-router";
import { useAuthStore } from "@/Stores/Auth.js";
import LanguageSelector from "@/Components/UI/LanguageSelector.vue";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";
import HttpApi from "@/Services/HttpApi.js";
import ShowCarousel from "@/Pages/Home/Components/ShowCarousel.vue";
import { useSettingStore } from "@/Stores/SettingStore.js";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import ShowProviders from "@/Pages/Home/Components/ShowProviders.vue";
import { searchGameStore } from "@/Stores/SearchGameStore.js";
import CustomPagination from "@/Components/UI/CustomPagination.vue";
import LastWinnersComponent from "@/Components/UI/LastWinnersComponent.vue";

export default {
  components: {
    CustomPagination,
    Pagination,
    ShowProviders,
    LoadingComponent,
    ShowCarousel,
    CassinoGameCard,
    Carousel,
    Navigation,
    Slide,
    LanguageSelector,
    MakeDeposit,
    BaseLayout,
    RouterLink,
    LastWinnersComponent
  },
  data() {
    return {
      isLoading: true,

      // banners settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        autoplay: 6000,
        wrapAround: true
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
      },

      settingsRecommended: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      breakpointsRecommended: {
        700: {
          itemsToShow: 3,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },

      // banners
      banners: null,
      bannersHome: null,
      preloadedImages: {},
      isImageLoaded: {},

      settingsGames: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
      breakpointsGames: {
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 6,
          snapAlign: 'start',
        },
      },
      providers: null,

      featured_games: null,
      categories: null,
    }
  },
  setup(props) {
    const ckCarouselOriginals = ref(null);

    onMounted(() => {
      // Qualquer lógica de montagem adicional
    });

    return {
      ckCarouselOriginals
    };
  },
  computed: {
    searchGameStore() {
      return searchGameStore();
    },
    userData() {
      const authStore = useAuthStore();
      return authStore.user;
    },
    isAuthenticated() {
      const authStore = useAuthStore();
      return authStore.isAuth;
    },
    setting() {
      const settingStore = useSettingStore();
      return settingStore.setting;
    }
  },
  async mounted() {
    await this.initializeMethods();
    this.preloadImages();
  },
  methods: {
  preloadImages() {
    if (this.banners && this.banners.length > 0) {
      this.banners.forEach(banner => {
        const img = new Image();
        img.src = `/storage/` + banner.image;
        img.onload = () => {
          this.preloadedImages[banner.image] = img.src;
          this.imageLoaded(img.src);
        };
      });
    }
  },
  imageLoaded(imageSrc) {
    this.isImageLoaded[imageSrc] = true;
  },
  async getCasinoCategories() {
    try {
      const response = await HttpApi.get('categories');
      this.categories = response.data.categories;
    } catch (error) {
      console.error("Error fetching casino categories:", error);
      Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });
    }
  },
  async toggleSearch() {
    this.searchGameStore.setSearchGameToogle();
  },
  async getBanners() {
    try {
      const response = await HttpApi.get('settings/banners');
      const allBanners = response.data.banners;

      this.banners = allBanners.filter(banner => banner.type === 'carousel');
      this.bannersHome = allBanners.filter(banner => banner.type === 'home');
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  },
  async getAllGames() {
    try {
      const response = await HttpApi.get('games/all');
      if (response.data !== undefined) {
        this.providers = response.data.providers;
        this.isLoading = false;
      }
    } catch (error) {
      console.error("Error fetching all games:", error);
      Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });
      this.isLoading = false;
    }
  },
  async getFeaturedGames() {
    try {
      const response = await HttpApi.get('featured/games');
      this.featured_games = response.data.featured_games;
      this.isLoading = false;
    } catch (error) {
      console.error("Error fetching featured games:", error);
      Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });
      this.isLoading = false;
    }
  },
  async initializeMethods() {
    await this.getCasinoCategories();
    await this.getBanners();
    await this.getAllGames();
    await this.getFeaturedGames();
  }
},
async created() {
  await this.initializeMethods();
},

};
</script>




<style scoped>
.text-provider {
    font-size: 18px;
    line-height: 1.75rem;
}
  
  

.placeholder {
  background-color: transparent;
}


.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--ci-primary-color);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  
</style>
