<template>
    <NavTopComponent :simple="false" v-if="!isMobile" />
    <NavComponentMobile :simple="false" v-else />

    <SideBarComponent v-if="!isMobile" />

    <div class="ml-0 md:ml-64 mt-5 md:mt-16">
        <slot></slot>
    </div>
</template>

<script>
import {initFlowbite} from 'flowbite';
import {ref, onMounted, onUnmounted, computed} from "vue";
import NavTopComponent from "@/Components/Nav/NavTopComponent.vue";
import NavComponentMobile from "@/Components/Nav/NavComponentMobile.vue";
import SideBarComponent from "@/Components/Nav/SideBarComponent.vue";
import FooterComponent from "@/Components/UI/FooterComponent.vue";
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import CookiesComponent from "@/Components/UI/CookiesComponent.vue";

export default {
    props: [],
    components: {CookiesComponent, BottomNavComponent, FooterComponent, SideBarComponent, NavTopComponent, NavComponentMobile},
    data() {
        return {
            logo: '/assets/images/logo_verde.png',
            isLoading: false,
        }
    },
    setup(props) {
        const width = ref(window.innerWidth);
        const isMobile = computed(() => width.value <= 768);
        
        function updateWidth() {
            width.value = window.innerWidth;
        }
        
        onMounted(() => {
            initFlowbite();
            window.addEventListener('resize', updateWidth);
        });
        
        onUnmounted(() => {
            window.removeEventListener('resize', updateWidth);
        });

        return {
            isMobile
        };
    },
    computed: {},
    mounted() {


        // setTimeout(() => {
        //     this.isLoading = false
        // }, 3000)
    },
    methods: {},
    watch: {},
};
</script>
